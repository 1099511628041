<template>
  <div v-show="loading" class="home">
    <van-popup v-model="show" @closed="closed" position="bottom" round :style="{ maxHeight: '60%'}">
      <div v-for="(item, index) in ScarceType" :key="index" class="scarceItem" @click="selectItem(item)">
        <div style="padding: 1rem 0;">
          <div style="display: flex; justify-content:center;">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <span style="font-size: 1rem;"> {{ item.name }}</span>
            </div>
          </div>

        </div>
        <div v-if="index !== (ScarceType.length - 1)" style="border-bottom: 1px solid #DDD;"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {

  },
  data() {
    return {
      selectIndex: 0,
      show: true,
      loading: true,
      value: 1,
      ScarceType: [
        { name: '与我联系', status: 0 },
        { name: '缺货退款', status: 1 },
        { name: '整单退款', status: 2 }
      ],
    };
  },
  created() {
    this.loading = true
  },

  components: {},
  methods: {
    closed() {
      this.$emit("closed", false);
    },
    selectItem(item) {
      this.$emit('selectScarceItem', item)
    }
  },
  mounted() {

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.scarceItem {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.default {
  border-radius: 0.2rem;
  font-size: 0.8rem;
  background-color: red;
  color: white;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}
</style>